import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { StreamProvider } from "./contexts/StreamContext";
import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";
import { SingleStreamProvider } from "./contexts/SingleStreamContext";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import RIP from "./pages/RIP/RIP";

const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const LiveStreams = lazy(() => import("./pages/LiveStreams/LiveStreams"));
const Login = lazy(() => import("./pages/Login/Login"));
const Nav = lazy(() => import("./components/organisms/Nav/Nav"));
const Footer = lazy(() => import("./components/organisms/Footer/Footer"));
const SingleStream = lazy(() => import("./pages/SingleStream/SingleStream"));


const pages = [
    { element: <Home />, path: "/" },
    { element: <About />, path: "/about" },
    { element: <Contact />, path: "/contact" },
    { element: <LiveStreams />, path: "/live-streams" },
    { element: <Login />, path: "/login" },
    { element: <SingleStream />, path: "/:streamSlug" },
    { element: <SwaggerUI url="/wp-content/plugins/ChurchCamLive/docs/openapi.yaml"/>, path: "/docs/api" }
    // {element: <RIP/>, path: "/rip"}
];

const routes = {
    "/": "Home",
    "/about": "About",
    "/contact": "Contact",
    "/live-streams/": "Live Streams",
    // "/rip": "RIP",
    "/login": "Login",
};

function App() {
    let content = (
        <>
            <Nav routes={routes} />
            <Routes>
                {pages.map((page, index) => (
                    <Route
                        key={index}
                        path={page.path}
                        element={page.element}
                    />
                ))}
                <Route
                    path="*"
                    element={<Navigate to="/login" />}
                />
            </Routes>
            <Footer routes={routes} />
        </>
    );

    return (
        <div className="d-flex flex-column min-vh-100">
            <Router>
                <Suspense
                    fallback={
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#0f6fb5ff",
                                fontSize: "25px",
                            }}
                        >
                            Loading...
                        </div>
                    }
                >
                    <StreamProvider>
                        <SingleStreamProvider>{content}</SingleStreamProvider>
                    </StreamProvider>
                </Suspense>
                <ScrollToTop />
            </Router>
        </div>
    );
}

export default App;
